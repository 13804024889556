<template>
  <div class="email-tracking-detail-content-container col-12">
    <CCard>
      <CCardHeader>
        <h1>{{ $t('TRACKING_EMAIL_DETAIL_PAGE_LABEL_TITLE') }}</h1>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="1" md="1">
            <label class="col-form-label">{{ $t('TRACKING_EMAIL_DETAIL_PAGE_EMAIL_LABEL') }}</label>
          </CCol>
          <CCol lg="5" md="5">
            <div class="form-control-plaintext">
              <span>{{ emailTrackingDetails.email }}</span>
            </div>
          </CCol>
          <CCol lg="1" md="1">
            <label class="col-form-label">{{ $t('TRACKING_EMAIL_DETAIL_PAGE_STATUS_LABEL') }}</label>
          </CCol>
          <CCol lg="5" md="5">
            <div class="status-detail"><StakingBadge type="tracking-email-status" :text="emailTrackingDetails.status" /></div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="1" md="1">
            <label class="col-form-label">{{ $t('TRACKING_EMAIL_DETAIL_PAGE_SUBJECT_LABEL') }}</label>
          </CCol>
          <CCol lg="5" md="5">
            <div class="form-control-plaintext">
              <span>{{ emailTrackingDetails.subject }}</span>
            </div>
          </CCol>
          <CCol lg="1" md="1">
            <label class="col-form-label">{{ $t('TRACKING_EMAIL_DETAIL_PAGE_READ_LABEL') }}</label>
          </CCol>
          <CCol lg="5" md="5">
            <CIcon v-if="emailTrackingDetails.num_of_views > 0" class="read-icon" name="cil-check" />
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="1" md="1">
            <label class="col-form-label">{{ $t('TRACKING_EMAIL_DETAIL_PAGE_SENT_AT_LABEL') }}</label>
          </CCol>
          <CCol lg="5" md="5">
            <div class="form-control-plaintext">
              <span>{{ emailTrackingDetails.createdAt | moment(dateFormat)}}</span>
            </div>
          </CCol>
          <CCol lg="1" md="1">
            <label class="col-form-label">{{ $t('TRACKING_EMAIL_DETAIL_PAGE_DIAGNOSTIC_CODE_LABEL') }}</label>
          </CCol>
          <CCol lg="5" md="5">
            <div class="form-control-plaintext">
              <span>{{ emailTrackingDetails.diagnostic_code}}</span>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import currencyHelper from '@/utils/currency-helper';
import { Constants, env } from '@/constants';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'TrackingEmailDetail',
  components: {
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      emailTrackingId: null,
      emailTrackingDetails: null,
      trackingEmailListUrl: '/admin/setting/tracking-emails',
      isLoading: true,
    };
  },
  async mounted() {
    this.emailTrackingId = this.$route.params.id;
    await Promise.all([this.getEmailTrackingsDetails(this.emailTrackingId)]);
    this.isLoading = false;
    console.log(this.emailTrackingDetails);
  },
  methods: {
    backToExchangeTransactionList() {
      this.$router.push(this.trackingEmailListUrl);
    },
    async getEmailTrackingsDetails(emailTrackingId) {
      try {
        const res = await this.$http.get(endpoints.getEmailTrackingsDetails(this.emailTrackingId));
        this.emailTrackingDetails = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EMAIL_TRACKING_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.email-tracking-detail-content-container {
  padding: 0 15px;
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
  h1 {
    color: #657187;
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 0px;
  }
  .status-detail {
    padding-top: 5px;
  }
  .read-icon {
    color: #5da30b;
  }
  textarea {
    &.form-control {
      height: 400px;
    }
  }
  .nav-pills {
    margin-bottom: 30px;
    .nav-link {
      background: #f1f1f1;
      margin-right: 10px;
      display: block;
      color: #353535;
      border-radius: 50px;
      padding: 5px 20px;
      &.active {
        background: #39f;
        color: #fff;
      }
    }
  }
}
</style>
